import {
  Chip,
  Container,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import { Theme } from '@material-ui/core/styles'
import MoreIcon from '@material-ui/icons/MoreVert'
import RefreshIcon from '@material-ui/icons/Refresh'
import { makeStyles } from '@material-ui/styles'
import { Button, Link } from 'gatsby-theme-material-ui'
import React, { useContext, useEffect, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useTranslation } from 'react-i18next'
import LogoPng from 'src/components/Atoms/Icons/LogoPng'
import { Label } from 'src/types/Label'
import { FirebaseContext } from 'src/utils/firebase'
import { navigation } from 'src/utils/navigation'

interface Props {}

const TopBar = ({}: Props) => {
  const { firebase, user, logout } = useContext(FirebaseContext)
  const [t] = useTranslation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [version] = useDocumentData<Label>(firebase.firestore().doc(`labels/appMerchantVersion`))
  const [reloadRequired, setReloadRequired] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (localStorage.getItem('currentVersion') === null && version?.value) {
    localStorage.setItem('currentVersion', version?.value)
  }

  useEffect(() => {
    if (version?.value) {
      const currentVersion = Number(localStorage.getItem('currentVersion'))
      const remoteVersion = Number(version?.value)
      setReloadRequired(currentVersion > 0 && remoteVersion !== currentVersion)
    }
  }, [version])

  return (
    <>
      <AppBar color="inherit" elevation={0}>
        {reloadRequired && (
          <Container className={classes.reloadContainer}>
            {t('reload.message')}
            &nbsp;
            <Button
              variant="contained"
              color="secondary"
              onClick={async () => {
                // before the reload we update the value
                if (version?.value) {
                  localStorage.setItem('currentVersion', version?.value)
                }
                const cacheNames = await caches.keys()
                for await (const cacheName of cacheNames) {
                  await caches.delete(cacheName)
                  console.log(cacheName)
                }
                window.location.reload(true)
              }}>
              <RefreshIcon />
              &nbsp;
              {t('action.reload')}
            </Button>
          </Container>
        )}

        <Container className={classes.mainContainer}>
          <Link to="/" className={classes.logoDesktop}>
            <LogoPng />
          </Link>
          <div className={classes.logoMobile}>
            <LogoPng color={'black'} />
          </div>

          {process.env.GATSBY_FIREBASE_PROJECT_ID === 'yodo-club-dev' &&
            process.env.GATSBY_CONTEXT === 'local' && (
              <Chip color="secondary" label='data "local"' />
            )}
          {process.env.GATSBY_FIREBASE_PROJECT_ID === 'yodo-club-dev' &&
            process.env.GATSBY_CONTEXT !== 'local' && <Chip color="secondary" label='data "dev"' />}
          {process.env.GATSBY_FIREBASE_PROJECT_ID === 'yodo-club-quality' && (
            <Chip color="secondary" label='data "quality"' />
          )}
          <div className={classes.grow} />
          {user && (
            <>
              <div className={classes.sectionDesktop}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.topBarMenuItem}
                  to={navigation.toDealNew}>
                  {t('menu.addDeal')}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.topBarMenuItem}
                  to={navigation.toDeals}>
                  {t('menu.myDeals')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.topBarMenuItem}
                  to={navigation.toSalesManagement}>
                  {t('menu.salesManagement')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.topBarMenuItem}
                  to={navigation.toMerchant}>
                  {t('menu.myBusiness')}
                </Button>

                <Button variant="contained" color="primary" to={navigation.toProfile}>
                  {t('menu.myProfile')}
                </Button>
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit">
                  <MoreIcon />
                </IconButton>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem onClick={handleClose}>
                    <Link to={navigation.toDealNew} className={classes.topBarMenuLink}>
                      {t('menu.addDeal')}
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleClose}>
                    <Link to={navigation.toDeals} className={classes.topBarMenuLink}>
                      {t('menu.myDeals')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={navigation.toSalesManagement} className={classes.topBarMenuLink}>
                      {t('menu.salesManagement')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={navigation.toMerchant} className={classes.topBarMenuLink}>
                      {t('menu.myBusiness')}
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleClose}>
                    <Link to={navigation.toProfile} className={classes.topBarMenuLink}>
                      {t('menu.myProfile')}
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose()
                      logout()
                    }}>
                    {t('menu.logout')}
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Container>
        <Container>
          <Typography variant={'subtitle1'} className={classes.topBarTitle}>
            console commerçant
          </Typography>
        </Container>
      </AppBar>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex'
    },
    reloadContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'end',
      display: 'flex'
    },
    grow: {
      flexGrow: 1
    },
    topBarMenuItem: {
      marginRight: theme.spacing(1)
    },
    logoDesktop: {},
    logoMobile: { display: 'none' },
    topBarMenuLink: {
      textDecoration: 'none !important',
      color: '#fff'
    },
    topBarTitle: {},
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    [`@media print`]: {
      topBarTitle: {
        display: 'none'
      },
      logoMobile: { display: 'block' },
      logoDesktop: {
        display: 'none'
      },
      reloadContainer: {
        display: 'none'
      }
    }
  })
)

export default TopBar
