export const navigation = {
  toDeals: '/deals/',
  toDealNew: '/deals/new/',
  toDealEdit: '/deals/edit/',
  toDealDuplicate: '/deals/duplicate/',
  toMerchant: '/merchant/',
  toProfile: '/profile/',
  toSalesManagement: '/salesManagement/',
  toStatement: '/statement/'
}
